<template>
  <div>
    <el-table
      :height="tableHeight"
      :data="displayDatas"
      tooltip-effect="dark"
      stripe
      style="width: 100%"
    >
      <el-table-column type="index" width="50" />
      <el-table-column prop="name" label="名称" />
      <el-table-column prop="phone" label="手机号" />
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button @click="onDelProduct(scope.row)" type="text" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="xa-cell xa-txt-placeholder">
      <div class="xa-flex">已选：优惠券（{{ datas.length }}）</div>
      <el-pagination
        layout="prev, pager, next"
        :page-count="totoPid"
        :current-page="pid + 1"
        @current-change="onChangePage"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import Base from './DisplayProductTable'
export default {
  extends: Base
}
</script>
